
import { Component, Vue } from 'vue-property-decorator';

import FormInput from '@/components/common/FormInput.vue';
import ErrorDisplay from '@/components/common/ErrorDisplay.vue';

import User from '@/store/modules/User';
import ApiError from '@/services/api/models/ApiError';
import { setUpUser } from '@/services/Mixpanel';
import { Validation, validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { isUat } from '@/services/api/Environment';

@Component({
  components: {
    FormInput,
    ErrorDisplay
  },
  methods: {
    isUat
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  mixins: [validationMixin]
})
export default class Login extends Vue {
  public error: ApiError | null = null;
  public form = {
    email: '',
    password: ''
  };
  public notAPartner = false;
  public loading = false;

  public async onSubmit(): Promise<void> {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }
    this.error = null;
    try {
      this.loading = true;
      await User.login(this.form);
      setUpUser();
      if (User.token.orgs[0].productTypes.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.push('/milk-view').catch(() => {}); // Change this to /home when ready.
      }
    } catch (error) {
      this.error = error as ApiError;
      if (this.error?.errorMessage == 'Not a partner.') {
        this.notAPartner = true;
      } else if (!this.error?.errorMessage) {
        this.error = {
          errorMessage:
            'Something went wrong. Please contact Levno to resolve this issue.'
        };
      }
    }
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  public validateState(name: string): boolean | null {
    const { $dirty, $invalid } = this.$v.form[name] as Validation;
    return $dirty ? !$invalid : null;
  }
}
