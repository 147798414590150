var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-100 bg-primary"},[_c('BContainer',{staticClass:"h-100 justify-content-center align-items-center d-flex"},[_c('BForm',{staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('img',{staticClass:"levno-banner pb-2",attrs:{"src":require("@/assets/levno_logo.svg")}}),_c('h5',{staticClass:"text-white pt-3"},[_vm._v("Partner"+_vm._s(_vm.isUat() ? ' UAT' : ''))]),_c('div',{staticClass:"text-center",style:(_vm.loading ? 'display:inline-block' : 'display:none')},[_c('b-spinner',{staticClass:"mt-4",attrs:{"variant":"secondary","id":"spinner2","label":"Text Centered"}})],1),(!_vm.loading)?_c('div',[_c('FormInput',{attrs:{"inputAttrs":{
            id: 'email',
            label: 'Email',
            labelClass: 'text-secondary',
            type: 'email',
            autocomplete: 'email',
            state: _vm.validateState('email'),
            errorMessage: 'Please enter a valid email'
          },"id":"email"},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", $$v)},expression:"$v.form.email.$model"}}),_c('FormInput',{attrs:{"inputAttrs":{
            id: 'password',
            label: 'Password',
            labelClass: 'text-secondary',
            type: 'password',
            autocomplete: 'current-password',
            state: _vm.validateState('password'),
            errorMessage: 'Please enter your password'
          },"id":"password"},model:{value:(_vm.$v.form.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.password, "$model", $$v)},expression:"$v.form.password.$model"}}),_c('BButton',{staticClass:"text-light w-100 mt-2 mb-3",attrs:{"type":"submit"}},[_vm._v("Login")]),_c('a',{staticClass:"my-3 py-3 text-secondary",attrs:{"href":"/auth/forgot-password"}},[_vm._v("Forgot password?")]),(_vm.notAPartner)?_c('p',{staticClass:"text-white pt-4"},[_vm._v(" The email you have entered is not a "),_c('br'),_vm._v(" valid partner login. ")]):_vm._e(),(_vm.notAPartner)?_c('BButton',{staticClass:"text-light mt-2 mb-3",attrs:{"href":"https://app.levno.com/"}},[_vm._v("Levno Customer Login")]):_vm._e(),(_vm.error && !_vm.notAPartner)?_c('ErrorDisplay',{attrs:{"error":_vm.error}}):_vm._e()],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }